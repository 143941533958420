/*eslint-disable max-len*/
import { UserType } from '../../core/users/users.types';
import store from '../../shared/Store';
import { replaceValues } from '../../utils/stringUtils';

const { endpoints } = window.__DATA__;

export function getUserBadgeUri(badge: Record<string, any>, user: UserType): string {
  if (!badge) return '';
  const { templateUrl } = badge;
  // eslint-disable-next-line max-len
  const badgeUrl = `${endpoints.document}/${store.eventId}/badges/users/${user.collection}/${user.privateKey}/badge.pdf`;
  if (templateUrl) {
    return replaceValues(templateUrl, { url: badgeUrl });
  }
  return badgeUrl;
}

export function getUserTemplateDocument(documentTemplateId: string, user: UserType): string | null {
  if (!documentTemplateId) return null;
  return `${endpoints.document}/${store.eventId}/documents/generate-pdf/files/${documentTemplateId}/users/${user.privateKey}`;
}
