import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { useQuery, useQueryClient } from 'react-query';

import socialWallService from '../../core/services/social-wall.service';
import { showBlacklistError } from '../../social-wall/socialWallUtils';
import { MINUTE, SECOND } from '../../utils/timeUtils';
import { Media, Publication, UpdatedPublication } from '../types/SocialWallV2.types';

type UseSocialWallPublications = {
  isLoading: boolean;
  publications: Publication[];
  createPublication: (message: string, media?: Media) => Promise<Publication | undefined>;
  deletePublication: (messageId: string) => Promise<void>;
  editPublication: (
    initPublication: Publication,
    newPublication: { message?: string; media?: Media; thumbnail?: Media },
  ) => Promise<Publication | undefined>;
};

const REFETCH_INTERVAL = 30 * SECOND;
const CACHE_TIME = 5 * MINUTE;

const notifyOnChangeProps: ('data' | 'error' | 'isLoading')[] = ['isLoading', 'data', 'error'];

function getSocialWallPublications(publications: Publication[]) {
  // TODO: orderedBy publishedAt when key will be available
  const orderedPublications = orderBy(publications, 'createdAt', 'desc');
  const [pinned, unpinned] = partition(orderedPublications, (post) => post.data?.pinned);
  return [...pinned, ...unpinned];
}

export const useSocialWallPublications = (
  publicationEnabled?: boolean,
): UseSocialWallPublications => {
  const queryClient = useQueryClient();

  const { data: previewData, isLoading } = useQuery(
    ['social-wall', 'publications-preview'],
    async () => {
      const publications = await socialWallService.getPublished({ limit: 50 });
      return getSocialWallPublications(publications);
    },
    {
      notifyOnChangeProps,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME / 2, // Don't refetch if we already have some
    },
  );

  const { data: fullData } = useQuery(
    ['social-wall', 'publications'],
    async () => {
      const publications = await socialWallService.getPublished();
      return getSocialWallPublications(publications);
    },
    { notifyOnChangeProps, cacheTime: CACHE_TIME, refetchInterval: REFETCH_INTERVAL },
  );

  const data = fullData ?? previewData ?? [];

  const createPublication = async (
    message: string,
    media?: Media,
    thumbnail?: Media,
  ): Promise<Publication | undefined> => {
    if (!publicationEnabled) return undefined;
    const postMessage = await socialWallService.postMessage({
      message,
      media,
      thumbnail,
    });

    if (postMessage?.errors) {
      showBlacklistError(postMessage, message);
      return undefined;
    }

    queryClient.invalidateQueries({ queryKey: ['social-wall', 'publications'] });
    return postMessage;
  };

  const deletePublication = async (publicationId: string): Promise<void> => {
    await socialWallService.deleteMessage(publicationId);
    queryClient.invalidateQueries({ queryKey: ['social-wall', 'publications'] });
  };

  const editPublication = async (
    initPublication: Publication,
    newPublication: UpdatedPublication,
  ) => {
    if (!initPublication) return undefined;

    const { message, media, thumbnail } = newPublication;

    const patchMessage = {
      ...initPublication,
      data: {
        content: message,
        media,
        thumbnail,
      },
    };

    const { success, errors } = await socialWallService.patchMessage(
      initPublication._id,
      newPublication,
    );
    if (errors) {
      showBlacklistError({ errors }, newPublication.message);
      return undefined;
    }
    if (!success) return undefined;

    // Invalidate query to launch getPosts
    queryClient.invalidateQueries({ queryKey: ['social-wall', 'publications'] });
    return patchMessage;
  };
  return {
    isLoading,
    publications: data,
    createPublication,
    deletePublication,
    editPublication,
  };
};

type UseSocialWallPublication = {
  publication: Publication;
  deletePost: () => Promise<void>;
  editPost: (newPublication: {
    message?: string;
    media?: Media;
    thumbnail?: Media;
  }) => Promise<Publication | undefined>;
};
export const useSocialWallPublication = (publicationId: string): UseSocialWallPublication => {
  const { publications, deletePublication, editPublication } = useSocialWallPublications();
  const publication = publications.find((p) => p._id === publicationId) as Publication;

  const deletePost = async () => {
    await deletePublication(publicationId);
  };

  const editPost = async (newPublication: {
    message?: string;
    media?: Media;
    thumbnail?: Media;
  }) => {
    return editPublication(publication, newPublication);
  };
  return { publication, deletePost, editPost };
};
