/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable operator-linebreak */
import { addMilliseconds, isAfter, parseISO } from 'date-fns';
import get from 'lodash/get';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { TrackingContext } from '../../Context';
import { fetchAgenda } from '../../agenda/store/agenda.actions';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { useScreenConfig } from '../../config/screens.context';
import feedbackService from '../../core/services/feedback.service';
import platformService from '../../core/services/platform.service';
import { eventTags } from '../../core/trackers/events';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import store from '../../shared/Store';
import { useCurrentUser } from '../../shared/store-user.hooks';
import {
  showBookConflict,
  showBookConflictConfirm,
} from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { fetchRegistrations } from '../../store/actions';
import { getString } from '../../utils';
import { findOverlappingEvent } from '../../utils/agendaUtils';
import { sweetAlert, sweetConfirmAlert } from '../../utils/popupUtils';
import workshopSessionService from '../../workshop-session/services/workshop-session.service';
import FeedbackModal from '../components/FeedbackModal';

const translationPrefix = 'workshops.workshop';

function showError(code) {
  let title = '';
  switch (code) {
    case 'E_UNAVAILABLE_USER':
      title = getString(`agenda.unavailable-user`);
      break;
    case 'E_WORKSHOP_FULL':
      title = getString(`${translationPrefix}.full-workshop`);
      break;
    default:
      title = getString(`${translationPrefix}.workshop-registration-error`);
  }

  return sweetAlert({ icon: 'error', title });
}

const defaultLockConfig = {
  enable: false,
  lockTimeOffset: 0,
};

async function registerAgendaSession(workshopOrSession) {
  const isSession = !!workshopOrSession.workshopId;
  if (isSession) {
    const { collection, workshopId, _id } = workshopOrSession;
    return workshopSessionService.registerWorkshopSession(collection, workshopId, _id);
  }
  return platformService.registerSession(workshopOrSession._id);
}

async function unregisterAgendaSession(workshopOrSession) {
  const isSession = !!workshopOrSession.workshopId;
  if (isSession) {
    const { collection, workshopId, _id } = workshopOrSession;
    return workshopSessionService.unregisterFromWorkshopSession(collection, workshopId, _id);
  }
  return platformService.unregisterSession(workshopOrSession._id);
}

async function ensureRegistrationFeedback(workshop) {
  const { status, ...rest } = await feedbackService.checkWorkshopFeedback(
    workshop.registrationFeedback?.uri,
    workshop._id,
  );
  if (status === 'display') {
    // Time to show the feedback... We'll be free to go afterwards, sorry
    const res = await FeedbackModal.open({ uri: rest.uri, item: workshop, autoClose: true });
    console.log('res', res);
    return !!res;
  }

  if (status === 'done' || status === 'none') {
    return true;
  }
  console.warn('Unknown feedback status', status, rest);
  return true;
}

export async function registerWorkshop(
  workshop,
  agenda,
  { trackEvent, setLoading, allowOverlappingSessions },
) {
  setLoading(true);
  try {
    const overlappingEvent = findOverlappingEvent(agenda, workshop);
    if (overlappingEvent && !allowOverlappingSessions) {
      await showBookConflict(overlappingEvent);
      return;
    }

    const userIsAvailableOrStillWantToBook =
      overlappingEvent === null ||
      overlappingEvent === false ||
      overlappingEvent === undefined ||
      (await showBookConflictConfirm(overlappingEvent));

    if (!userIsAvailableOrStillWantToBook) {
      return;
    }

    if (!(await ensureRegistrationFeedback(workshop))) {
      return;
    }

    const res = await registerAgendaSession(workshop);
    if (res.success) {
      trackEvent(eventTags.WORKSHOP_REGISTER, {
        userId: store.userId,
        workshop,
        item: workshop,
      });
      store.reduxStore.dispatch(fetchRegistrations());
      store.reduxStore.dispatch(fetchAgenda());
      toast(getString(`${translationPrefix}.successful-registration`));
    } else if (res.errors) {
      const { code } = res.errors[0];
      if (code === 'E_WORKSHOP_FULL') {
        showError(code);
        store.reduxStore.dispatch(fetchRegistrations());
      } else showError('ERROR');
    }
  } catch (error) {
    console.error(error);
    showError('ERROR');
  } finally {
    setLoading(false);
  }
}

export async function unregisterWorkshop(workshop, { setLoading, trackEvent }) {
  const confirm = await sweetConfirmAlert({
    title: getString(`${translationPrefix}.unregister-confirm`, {
      name: workshop.title,
    }),
    confirmButtonText: getString(`alert.confirm`),
    cancelButtonText: getString(`alert.cancel`),
  });
  if (confirm) {
    trackEvent(eventTags.WORKSHOP_UNREGISTER, {
      userId: store.userId,
      workshop,
      item: workshop,
    });
    if (setLoading) setLoading(true);
    await unregisterAgendaSession(workshop);
    store.reduxStore.dispatch(fetchRegistrations());
    store.reduxStore.dispatch(fetchAgenda());
    toast(getString(`${translationPrefix}.successful-cancel`, { name: workshop.title }));

    if (setLoading) setLoading(false);
  }
}

function isWorkshopEnded(now, endDate, lockConfig = {}) {
  const { enable = true, lockTimeOffset = 0 } = lockConfig;
  return enable && isAfter(addMilliseconds(now, -lockTimeOffset), parseISO(endDate));
}

function extractDay(workshop) {
  return moment(workshop.startDate, moment.ISO_8601).format('YYYY-MM-DD');
}

export function computeRegistrationState(workshop, registration, agenda = [], options = {}) {
  const workshopDay = extractDay(workshop);
  const { usersCount, quota } = workshop;
  const isWorkshopFull = quota ? quota <= usersCount : false;

  const { constraints } = options;
  if (registration) {
    return {
      state: 'REGISTERED',
      isWorkshopFull,
    };
  }

  if (isWorkshopFull) {
    return {
      registrationOpen: false,
      state: 'CLOSED_FULL',
      isWorkshopFull,
      // Used for messages
      metadata: {
        quota,
      },
    };
  }

  if (constraints?.registrationsPerDay) {
    // Check registrations per day... TODO: timezone ?
    const { max, ignoreMandatory } = constraints?.registrationsPerDay[workshopDay] || {};
    if (max) {
      // Check for current count...
      let daySessions = agenda.filter(
        (w) => (w._type === 'session' || w._type === 'workshop') && extractDay(w) === workshopDay,
      );
      if (ignoreMandatory) {
        // Remove mandatory sessions
        daySessions = daySessions.filter((session) => !session.mandatory);
      }
      if (daySessions.length >= max) {
        return {
          registrationOpen: false,
          state: 'CLOSED_MAX_REGISTRATIONS_PER_DAY',
          isWorkshopFull,
          // Used for messages
          metadata: {
            max,
            daySessions,
          },
        };
      }
      // const registrations = agenda
    }
  }
  return {
    registrationOpen: true,
    state: 'OPEN',
    isWorkshopFull,
  };
}

export function useWorkshopRegistration(workshop = {}, registration, options = {}) {
  const agenda = useSelector((state) => userAgenda(state));
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useContext(TrackingContext);
  const { lockAfterEnd: lockConfig = defaultLockConfig, allowOverlappingSessions = false } =
    useScreenConfig('workshops')?.workshop || {};
  const now = useAutoRefresh(30000);
  const { endDate } = workshop;
  const ended = isWorkshopEnded(now, endDate, lockConfig);

  const registrationState = computeRegistrationState(workshop, registration, agenda, options);

  return {
    isWorkshopFull: registrationState.isWorkshopFull,
    isWorkshopEnded: ended,
    registrationState,
    registerWorkshop: () =>
      registerWorkshop(workshop, agenda, {
        trackEvent,
        setLoading,
        allowOverlappingSessions,
      }),
    loading,
    unregisterWorkshop: () => unregisterWorkshop(workshop, { setLoading, trackEvent }),
  };
}

export function useWorkshopRelatedEvent(workshop = {}) {
  const { url = '' } = workshop;

  const eventId = useMemo(() => {
    const match = url.match(/\{user\.(\w+)\.userId\}/);
    const eid = match?.[1];
    return eid || null;
  }, [url]);

  const user = useCurrentUser();
  const status = eventId ? get(user, [eventId, 'status'], 'pending') : null;

  return { eventId, status };
}

export const useRegistrationsById = (hasSession) => {
  return useSelector((state) =>
    hasSession
      ? state.registrations.registrationsBySessionId
      : state.registrations.registrationsById,
  );
};
