import { css } from '@emotion/react';
import keys from 'lodash/keys';
import PropTypes from 'prop-types';
import React from 'react';

import './authentication.scss';
import { useLoginConfig } from './hooks';
import { AsideLoginLayout, ModalLoginLayout } from './variants';

const DEFAULT_VARIANT = 'modal';
const layoutVariants = {
  aside: AsideLoginLayout,
  modal: ModalLoginLayout,
};

const LoginLayout = ({ children, variant, error, screen, sideSection }) => {
  const LayoutComponent = layoutVariants[variant] || layoutVariants[DEFAULT_VARIANT];
  const login = useLoginConfig();
  const { customCSS } = login.design || {};
  return (
    <div css={css(customCSS)}>
      <LayoutComponent error={error} screen={screen} sideSection={sideSection}>
        {children}
      </LayoutComponent>
    </div>
  );
};

LoginLayout.defaultProps = {
  error: false,
  screen: undefined,
  variant: DEFAULT_VARIANT,
  sideSection: undefined,
};

LoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
  variant: PropTypes.oneOf(keys(layoutVariants)),
  error: PropTypes.bool,
  screen: PropTypes.string,
  sideSection: PropTypes.object,
};

export default LoginLayout;
