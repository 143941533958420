/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { Label, SemanticSIZES } from 'semantic-ui-react';

import { useItemValueColor } from '../utils/designUtils';
import { Entity, useEntityTags } from '../utils/hooks';

type Tag = {
  field: string;
  value: string;
};

type EntityTagsProps = {
  entity: Entity;
  type: string;
  size?: SemanticSIZES;
  tagFields: string[];
};

const StyledTag = ({ entity, tag, size }: { entity: Entity; tag: Tag; size?: SemanticSIZES }) => {
  const { style } = useItemValueColor(entity.kind!, entity, tag.field);
  return (
    <Label size={size} style={style} className={`tags--tag tags--tag-${tag}`} key={tag.field}>
      {tag.value}
    </Label>
  );
};

export const EntityTags: FC<EntityTagsProps> = ({ entity, type, size, tagFields }) => {
  const tags = useEntityTags(entity, type, tagFields);
  if (!tags || !tags.length) return null;
  return (
    <div className="entity tags">
      {tags.map((tag, index) => (
        <StyledTag key={index} tag={tag} size={size} entity={entity} />
      ))}
    </div>
  );
};
