/* eslint-disable react/require-default-props */
import React from 'react';

import Contacts, { ContactsProps } from '../components/Contacts';
import { ContactsCSSProps, useContactsCSS } from '../hooks';
import { ContactsDesign } from '../types';
import './ContactsVariantDefault.scss';

function actionBackgroundColor({ actions, design }: ContactsCSSProps): string[] {
  return (actions || []).map(
    ({ _id, color }) => `
.Action--id-${_id} {
  background-color: ${color || design?.colors?.primary};
}`,
  );
}

const ContactsVariantCompact = (props: ContactsProps & { design: ContactsDesign }): JSX.Element => {
  const style = useContactsCSS(props, actionBackgroundColor);
  return <Contacts css={style} {...props} />;
};

export { ContactsVariantCompact };
