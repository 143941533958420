import { css } from '@emotion/react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useMedia } from 'react-media';
import { Container } from 'semantic-ui-react';

import BottomTabsMenu from '../Layouts/Default/components/BottomTabsMenu';
import { HorizontalMenu } from '../Layouts/Default/components/HorizontalMenu';
import VerticalMenu from '../Layouts/Default/components/VerticalMenu';
import VerticalMenuHeader from '../Layouts/Default/components/VerticalMenu/components/VerticalMenuHeader';
import Styles from '../Styles';
import BackButton from '../components/BackButton';
import LightBackButton from '../components/BackButton/LightBackButton';
import CdnImage from '../components/CdnImage';
import Footer from '../components/Footer';
import PageBackground from '../components/PageBackground';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { useIsPreview } from '../config/preview.context';
import { useScreensConfig } from '../config/screens.context';
import { bem } from '../core/design/bem';
import { useCurrentScreen } from '../core/hooks/useCurrentScreen.hook';
import PageMeta from '../core/metadata/PageMeta';
import { useIsScrolled } from '../hooks/useIsScrolled';
// eslint-disable-next-line import/no-cycle
import { imageProptypes } from '../propTypes';
import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';
import './CmsPage.scss';
import { CmsBlocks } from './CmsScreen';

const pageClassName = bem('page');

const menuContainer = bem('menu-container');

const headerProps = {
  backgroundColor: PropTypes.string,
  logo: imageProptypes,
};

const CmsPageHeader = ({ logo, backgroundColor }) => {
  if (!logo) return null;
  return (
    <div className="header--fixed" style={{ backgroundColor }}>
      <div className="container">
        <CdnImage className="logo" maxHeight={100} src={logo} as="img" />
      </div>
    </div>
  );
};

CmsPageHeader.defaultProps = {
  logo: undefined,
  backgroundColor: undefined,
};
CmsPageHeader.propTypes = headerProps;

export const CmsPageLayout = ({
  className,
  header,
  cmsHeader,
  metadata,
  item,
  pageFooter,
  pageHeader,
  designOverride,
  children,
  theme: pageTheme,
  fullheight,
  withFooter,
}) => {
  const design = useDesignConfig();
  const { screenId } = useCurrentScreen();
  const { menu, menus } = useConfig();
  const scrolled = useIsScrolled();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const hasHeader = !!header;
  const background = designOverride.background || design.background; // page design
  const mobileMenu = design?.menu?.mobile || {};
  const { color: backgroundColor, image: backgroundImage, mobile: backgroundMobileImage } =
    background || {};
  const isPreview = useIsPreview();
  const isVerticalMenu = design?.menu?.wrapper?.variant === 'leftSidebar';
  const theme = pageTheme || design.theme || 'light';

  const hasBottomTabs =
    menus?.bottomTabs?.enabled &&
    ((matches.desktop && menus?.bottomTabs?.media?.desktop) ||
      (matches.mobile && menus?.bottomTabs?.media?.mobile));

  const filteredMenu = menu.filter((entry) => {
    if (!entry.allowedEntries?.length) return true;
    return entry.allowedEntries.includes(screenId);
  });

  const hasImageOrVideo = (bg) => {
    return !!bg?.image || !!bg?.video;
  };

  return (
    <div
      className={cx(
        'page',
        'CmsPageLayout',
        className ? className.toString() : undefined,
        `CmsPageLayout--theme-${theme}`,
        {
          'headered': hasHeader,
          'page--verticalMenu': isVerticalMenu,
          'page--bottomTabs': hasBottomTabs,
          'page--scrolled': scrolled,
          'page--fullheight': fullheight,
        },
      )}
      css={css(designOverride?.css, designOverride?.cssStyle)}
    >
      {!isEmpty(designOverride) && <Styles designOverride={designOverride} />}
      {isVerticalMenu && filteredMenu && <VerticalMenu menu={filteredMenu} />}
      <PageMeta {...metadata} item={item} />
      <PageBackground
        {...background}
        image={backgroundImage || (!backgroundColor && design.homeBackground)}
        mobile={
          hasImageOrVideo(backgroundMobileImage)
            ? backgroundMobileImage
            : design.mobileHomeBackground
        }
      />
      {header && <CmsPageHeader {...header} />}
      <div className="page--cms-container">
        {!isVerticalMenu && !isPreview && (
          <div className={menuContainer({ 'fixed-mobile': mobileMenu.fixed, scrolled }).toString()}>
            {filteredMenu && <HorizontalMenu menu={filteredMenu} />}
          </div>
        )}
        {/** Deprecated => use instead pageHeader */}
        {cmsHeader && <CmsBlocks blocks={cmsHeader?.blocks} />}
        {pageHeader && <CmsBlocks blocks={pageHeader?.blocks} />}
        <div className="content">
          {!isPreview && (
            <div className="BackButton__container">
              <BackButton />
            </div>
          )}
          {isVerticalMenu && !isPreview && <VerticalMenuHeader design={design} />}
          {children}
        </div>
        {pageFooter && (
          <div className="page--cms-footer">
            <CmsBlocks blocks={pageFooter?.blocks} />
          </div>
        )}
      </div>
      {withFooter && <Footer />}
      {hasBottomTabs && <BottomTabsMenu {...menus.bottomTabs} />}
    </div>
  );
};

CmsPageLayout.defaultProps = {
  className: '',
  cmsHeader: undefined,
  header: undefined,
  designOverride: {},
  fullheight: false,
  item: undefined,
  metadata: undefined,
  theme: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
  withFooter: true,
};

CmsPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cmsHeader: PropTypes.object,
  designOverride: PropTypes.object,
  fullheight: PropTypes.bool,
  header: PropTypes.object,
  item: PropTypes.object,
  metadata: PropTypes.object,
  theme: PropTypes.string,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  withFooter: PropTypes.bool,
};

function CmsPage(props) {
  const {
    blocks,
    header,
    metadata,
    design,
    id,
    pageId,
    pageHeader,
    pageFooter,
    fullscreen = false,
    ...rest
  } = props;
  const { showGoBack } = rest;
  const Wrapper = fullscreen ? 'div' : Container;

  return (
    <CmsPageLayout
      className={pageClassName({
        [pageId]: true,
        cms: true,
      })
        .mix([id, pageId]) // Legacy format
        .toString()}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Wrapper className={pageClassName('wrapper').toString()}>
        <LightBackButton showGoBack={showGoBack} />
        <CmsBlocks blocks={blocks} {...rest} />
      </Wrapper>
    </CmsPageLayout>
  );
}

CmsPage.defaultProps = {
  blocks: [],
  design: {},
  fullscreen: false,
  header: {},
  id: '',
  item: undefined,
  metadata: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
};

CmsPage.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  design: PropTypes.object,
  fullscreen: PropTypes.bool,
  header: PropTypes.object,
  id: PropTypes.string,
  item: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageId: PropTypes.string.isRequired,
  pageHeader: PropTypes.object,
};

export default CmsPage;
