export const defaultStats = {
  VIDEO_VIEW_SECTION: {
    tag: 'VIDEO_VIEW_SECTION',
  },
  WORKSHOP_VIEW_LIVE: {
    tag: 'LIVE_CLICK',
  },
  WEBINAR_ENTER: {
    tag: 'WEBINAR_ENTER',
  },
  QRCODE_SCAN: {
    tag: 'QRCODE_SCAN',
  },
  AGENDA_COMPLETE: {
    tag: 'AGENDA_COMPLETE',
  },
  ITEM_CLICK: {
    tag: 'CLICK',
  },
  NETWORKING_SEND_MESSAGE: {
    tag: 'NETWORKING_SEND_MESSAGE',
  },
};
