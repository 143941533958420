/* eslint-disable react/require-default-props */
import React from 'react';

import Contacts, { ContactsProps } from '../components/Contacts';
import { useContactsCSS } from '../hooks';
import { ContactsDesign } from '../types';
import './ContactsVariantMaterial.scss';

const ContactsVariantMaterial = (
  props: ContactsProps & { design: ContactsDesign },
): JSX.Element => {
  const style = useContactsCSS(props);
  return <Contacts css={style} {...props} />;
};

export default ContactsVariantMaterial;
