import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon } from 'semantic-ui-react';

import CdnImage from '../../../../components/CdnImage';
import GlobalModalManager from '../../../../components/GlobalModalManager';
import QRCode, { QRCodeConfig } from '../../../../components/QRCode';
import { QueryModal } from '../../../../components/QueryModal/QueryModal';
import { useDesignConfig } from '../../../../config/design.context';
import { bem } from '../../../../core/design/bem';
import { UserType } from '../../../../core/users/users.types';
import { replaceValues } from '../../../../utils/stringUtils';
import { formatUsername } from '../../../../utils/userUtils';
import { getUserBadgeUri } from '../../utils';

const css = bem('QRcodeAction');

type QRcodeStatic = {
  open: (props: any) => Promise<boolean | null>;
};

type QRcodeType = {
  uri: string;
  badge?: { enabled?: boolean; templateUrl?: string };
  size: number;
  config?: QRCodeConfig;
  color?: string;
  backgroundColor?: string;
};

type QRcodeProps = {
  user: UserType;
  qrcode: QRcodeType;
  onCancel: () => void;
};

export const QRcode: FC<QRcodeProps> & QRcodeStatic = (props) => {
  const { qrcode, user, onCancel, ...restProps } = props;
  const { t } = useTranslation();
  const { primaryColor } = useDesignConfig();
  const { uri, color, backgroundColor, size, config, badge = {} } = qrcode || {};
  const badgeUrl = getUserBadgeUri(badge, user);

  return (
    <QueryModal
      className={cx('Action', 'QRCodeAction')}
      draggable
      open
      onCancel={onCancel}
      initialStep={80}
      closeIcon
      {...restProps}
    >
      <div className={css('text')}>
        <Header as="h3">{formatUsername(user)}</Header>
        {t('profile-v2.actions.qrcode.text')}
      </div>
      <div className={css('qrcode')}>
        {qrcode?.uri ? (
          <CdnImage src={replaceValues(uri, user)} />
        ) : (
          <QRCode
            width={size}
            height={size}
            data={user._id}
            qrcodeConfig={{
              color: color || primaryColor,
              backgroundColor: backgroundColor || '#FFF',
              ...(config as any),
            }}
          />
        )}
      </div>
      {badge?.enabled && !!badgeUrl && (
        <Button className={css('badge').toString()} primary as="a" target="_blank" href={badgeUrl}>
          <Icon className="icon" name="id badge outline" />
          <span>{t('profile.download-badge')}</span>
        </Button>
      )}
    </QueryModal>
  );
};

QRcode.open = async (props: QRcodeProps): Promise<boolean | null> => {
  return GlobalModalManager.open(QRcode, props);
};
