import { orderBy, padStart } from 'lodash';
import get from 'lodash/get';

export function sanitizeStringForCompare(value: string): string {
  if (typeof value === 'number') {
    return padStart(`${value}`, 8, '0');
  }
  if (!value || typeof value !== 'string') return value;
  return value.replace(/[0-9]+/g, (v) => padStart(v, 8, '0')).toLowerCase();
}

export function buildSortSanitizer(key: string[] | string): string | ((str: any) => string) {
  if (key === 'order') return key;
  return (obj: Record<string, never>) => sanitizeStringForCompare(get(obj, key));
}

export const smartStringOrder = (
  list: Record<number | string, any>[],
  key: string[] | string,
  order?: ('asc' | 'desc')[],
): Record<string, any>[] => {
  const keys = Array.isArray(key) ? key : [key];
  return orderBy(list, keys.map(buildSortSanitizer), order);
};
