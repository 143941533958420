export const SET_COLLECTIONS = 'users/SET_COLLECTIONS';
export const SET_COLLECTION = 'users/SET_COLLECTION';
export const SET_BY_ID = 'users/SET_BY_ID';

export type UserType = {
  _id: string;
  collection: string;
  firstName: string;
  lastName: string;
  email: string;
  userId?: string;
  thumbnail?: Record<string, any>;
  networking?: boolean;
  privateKey?: string;
};
