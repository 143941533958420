/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMount } from 'ahooks';
import cx from 'classnames';
import { matches } from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Header, Icon, Item, Label, Segment } from 'semantic-ui-react';

import { TrackingContext } from '../../Context';
import { CmsPageLayout } from '../../cms/CmsPage';
import BlockContainer from '../../components/BlockContainer';
import CdnImage from '../../components/CdnImage';
import ItemGridLayout from '../../components/ItemGridLayout';
import VideoEmbed from '../../components/VideoEmbed';
import CTATilesBlock from '../../components/cms/CTATilesBlock';
import HtmlBlock from '../../components/cms/HtmlBlock';
import ItemCTAPagesBlock from '../../components/cms/ItemCTAPagesBlock';
import SpeakersBlock from '../../components/cms/SpeakersBlock';
import DescriptionBlock from '../../components/sponsors/DescriptionBlock';
import { ExhibitorFavorite } from '../../components/sponsors/ExhibitorFavorite';
import { getSponsorString } from '../../components/sponsors/SponsorCard';
import VideosBlock from '../../components/video/VideosBlock';
import WorkshopSessionsBlock from '../../components/workshops/WorkshopSessionsBlock';
import WorkshopsMatchingBlock from '../../components/workshops/WorkshopsMatchingBlock';
import { useComponentOptions } from '../../config/design.context';
import { useIsPreview } from '../../config/preview.context';
import { bem } from '../../core/design/bem';
import { eventTags } from '../../core/trackers/events';
import { useFreshContributor } from '../../core/users/users.hooks';
import { findUser } from '../../core/users/users.select';
import { fetchUserCollections } from '../../core/users/users.utils';
import useTranslations from '../../hooks/useTranslations';
import { imageProptypes, userProptypes } from '../../propTypes';
import store from '../../shared/Store';
import { sidebarBlockComponents } from '../../sponsors/blocks';
import SponsorChat from '../../sponsors/blocks/SponsorChat';
import StandBlock from '../../sponsors/blocks/StandBlock';
import Images from '../../utils/Images';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import { replaceValues } from '../../utils/stringUtils';
import ItemGalleryBlock from '../../workshops/blocks/ItemGalleryBlock';
import ItemHtmlBlock from '../../workshops/blocks/ItemHtmlBlock';
import ItemAddressBlock from '../blocks/ItemAddressBlock';
import './ExhibitorPage.scss';

const pageBlock = bem('page');

const translationPrefix = 'sponsors.sponsor';

const chatConfigPropTypes = {
  enabled: PropTypes.bool,
  mode: PropTypes.string,
};

// TODO: move to CSS
const colors = {
  platinum: 'blue',
  gold: 'yellow',
  silver: 'grey',
};

export const SponsorBannerLogo = ({ banner, logo }) => {
  const imageProps = useComponentOptions('company-logos');
  return (
    <div
      className="banner-logo"
      style={{ margin: banner ? '-1em -1em 54px -1em' : 0, position: 'relative' }}
    >
      {banner && (
        <div
          className="banner"
          style={{
            backgroundImage: `url(${Images.cdn(banner)})`,
            width: '100%',
            paddingTop: '30%',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      )}
      <div className="logo">
        <CdnImage src={logo} maxHeight={90} {...imageProps} />
      </div>
    </div>
  );
};

const DetailsBlock = ({ item, fields, defaultBanner, videoProps, allowFavorites }) => {
  const { url, logo, name, banner, category, shortDescription, video, videoPlaceholder } = item;
  const exhibitorBanner = banner || defaultBanner;
  const { trackEvent } = useContext(TrackingContext);

  function trackGoToPage() {
    trackEvent(eventTags.WEBSITE_CLICK, {
      userId: store.userId,
      item,
    });
  }

  return (
    <Segment className="details">
      <div className={cx('details__container', { hasBanner: !!exhibitorBanner })}>
        <ExhibitorFavorite exhibitor={item} allowFavorites={allowFavorites} size="large" />
        <SponsorBannerLogo banner={exhibitorBanner} logo={logo} />
        <div>
          <Header className="title" as="h2" style={{ marginBottom: 0 }}>
            {name}
          </Header>
          <Item>
            <Item.Content>
              {url && (
                <Item.Description>
                  <a href={url} target="_blank" rel="noreferrer noopener" onClick={trackGoToPage}>
                    {url}
                  </a>
                </Item.Description>
              )}
            </Item.Content>
          </Item>
          {category && (
            <Label
              icon="trophy"
              className={`trophy--${category}`}
              color={colors[category]}
              content={getSponsorString(item, 'category')}
              style={{ margin: '10px 0px' }}
            />
          )}
        </div>
      </div>
      <Item>
        <Item.Content>
          <Item.Description>{shortDescription}</Item.Description>
          {!!video && <br />}
          {!!video && (
            <VideoEmbed
              item={item}
              video={video}
              icon="play"
              url={video.uri}
              {...video}
              // videoProps={{ disablePictureInPicture: true, controlsList: 'nodownload' }}
              videoProps={videoProps}
              allowFullScreen
              placeholder={Images.maxWidth(videoPlaceholder, 1280)}
            />
          )}
        </Item.Content>
      </Item>
      {(fields || [])
        .filter((field) => get(item, field.name))
        .map((field) => (
          <Item key={field.name} className={`details-field details-field--${field.name}`}>
            <Item.Content>
              <Item.Description>
                {field.icon && (
                  <>
                    <Icon name={field.icon} />{' '}
                  </>
                )}
                {get(item, field.name)}
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
    </Segment>
  );
};

const ItemCTATilesBlock = ({ container, item, field, ...props }) => {
  const { t } = useTranslations(translationPrefix);
  const blocks = get(item, field);
  if (!blocks || blocks.length === 0) return null;

  return (
    <BlockContainer
      {...container}
      className={pageBlock('cta', { variant: props?.variant }).toString()}
      header={replaceValues(container?.header || t('cta'), { ...item })}
    >
      <CTATilesBlock blocks={get(item, field)} {...props} />
    </BlockContainer>
  );
};

export const defaultMainBlocks = [
  { type: 'details' },
  { type: 'description' },
  { type: 'videos' },
  { type: 'workshops', field: 'sponsors' },
  {
    type: 'item/ctaTiles',
    field: 'ctaTiles',
    animation: 'scale',
    variant: 'square',
    itemsPerRow: 4,
    gridProps: { centered: true },
    container: {
      type: 'segment-light',
      // header: 'Hello world : {name}' // Can be defined
    },
  },
];

const mainBlockComponents = {
  'details': DetailsBlock,
  'gallery': ItemGalleryBlock,
  'html': HtmlBlock,
  'item/html': ItemHtmlBlock,
  'description': ({ item }) => <DescriptionBlock description={item.description} />,
  'videos': ({ item, videoProps }) => (
    <VideosBlock item={item} videos={item.videos} videoProps={videoProps} />
  ),
  'workshops': ({ item, field = 'sponsors', ...rest }) => (
    <WorkshopsMatchingBlock {...rest} field={field} value={item._id} />
  ),
  'sessions': WorkshopSessionsBlock,
  'item/address': ItemAddressBlock,
  'item/ctaTiles': ItemCTATilesBlock,
  'item/ctaPages': ItemCTAPagesBlock,
  'speakers': ({ item, ...block }) => <SpeakersBlock speakerIds={item.speakers} {...block} />,
  'stand': StandBlock,
};

export const Exhibitor = (props) => {
  const {
    user,
    leftSidebar,
    rightSidebar,
    banner: defaultBanner,
    chat: chatConfig,
    enableChat = chatConfig?.enabled, // keep `enableChat` for retro-compatibility
    mainBlocks = defaultMainBlocks,
    showGoBack: propShowGoBack,
    header,
    metadata,
    pageHeader,
    pageFooter,
    design,
  } = props;
  const { _id, collection } = user;
  const isPreview = useIsPreview();
  const history = useHistory();
  const { t } = useTranslation();
  const mediaQuery = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const marginBottom = mediaQuery.mobile ? 70 : 0;
  const showGoBack = propShowGoBack ?? mediaQuery.mobile;

  return (
    <CmsPageLayout
      className={pageBlock({
        'exhibitor': true,
        'exhibitor-collection': user.collection,
        'exhibitor-category': user.category,
        'exhibitor-id': user._id,
        [user.collection]: true, // DEPRECATED
        [user._id]: true, // DEPRECATED
      }).toString()}
      item={user}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <div className={`screen--type-sponsor ${collection}--${_id}`} style={{ marginBottom }}>
          {showGoBack && (
            <div className="go-back" onClick={() => history.goBack()}>
              <Icon name="chevron left" />
              {t(`${translationPrefix}.back`)}
            </div>
          )}
          <ItemGridLayout
            mainBlocks={mainBlocks}
            leftSidebar={leftSidebar}
            rightSidebar={rightSidebar}
            mainBlockComponents={mainBlockComponents}
            sidebarBlockComponents={sidebarBlockComponents}
            item={user}
            sharedProps={{
              translationPrefix,
              defaultBanner,
            }}
          />
          {!isPreview && enableChat && (
            <SponsorChat
              sponsorId={_id}
              sponsor={user}
              translationPrefix={translationPrefix}
              type={user.collection}
              mode={chatConfig?.mode}
            />
          )}
        </div>
      </Container>
    </CmsPageLayout>
  );
};

Exhibitor.defaultProps = {
  banner: undefined,
  user: {},
  chat: {},
  enableChat: undefined,
  showGoBack: undefined,
};

Exhibitor.propTypes = {
  banner: imageProptypes,
  user: PropTypes.shape(userProptypes),
  chat: PropTypes.shape(chatConfigPropTypes),
  enableChat: PropTypes.bool,
  showGoBack: PropTypes.bool,
};

const AutoRefreshExhibitor = (props) => {
  const { user } = props;
  useFreshContributor(user);
  return <Exhibitor {...props} />;
};

const ExhibitorPage = ({ match, ...page }) => {
  const userMatcher = matches(match?.params);
  // TODO: fetch by slug ?
  const user = useSelector((state) => findUser(state, userMatcher));

  useMount(() => {
    const { entities } = page;
    if (!user && entities) {
      // Go fetch, find collection
      fetchUserCollections(entities.map((e) => e.collection));
    }
  });

  // Force fetch ?

  if (!user) return null;
  return <AutoRefreshExhibitor user={user} {...page} />;
};

ExhibitorPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ExhibitorPage;
