import cx from 'classnames';
import { Item } from 'semantic-ui-react';

import { useComponentOptions } from '../../../../config/design.context';
import SquareImage from '../../../SquareImage';
import { Item as ItemProps } from '../types';

type ClassicCircleProps = {
  as?: JSX.Element | undefined;
  item: ItemProps;
  imageSize?: number;
  onClick?: (...args: any[]) => any;
};

const ClassicCircle = ({ as, item, imageSize, onClick }: ClassicCircleProps): JSX.Element => {
  const { image, title, description, subDescription, category } = item;
  console.log(category);
  const imageProps = useComponentOptions('thumbnails');
  return (
    <Item.Group>
      <Item
        as={!onClick && as ? as : undefined}
        entity={{ ...item, kind: 'users' }}
        onClick={onClick && !as ? () => onClick(item) : undefined}
        className={category && `categorty--${category}`}
      >
        {image && (
          <SquareImage
            src={image}
            srcSet
            imageSize={imageSize}
            cdnOptions={{}}
            {...imageProps}
            circular
            style={{ width: imageSize, height: imageSize }}
            alt={title}
          />
        )}
        <Item.Content verticalAlign="middle">
          <Item.Header>{title}</Item.Header>
          {!!description && <Item.Description>{description}</Item.Description>}
          {!!subDescription && (
            <Item.Description className="sub-description">{subDescription}</Item.Description>
          )}
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

ClassicCircle.defaultProps = {
  as: undefined,
  imageSize: 100,
  onClick: undefined,
};

export default ClassicCircle;
