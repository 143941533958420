/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMount } from 'ahooks';
import cx from 'classnames';
import { parseISO } from 'date-fns';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { Card, Header, Icon } from 'semantic-ui-react';

import { fetchAgenda } from '../../../agenda/store/agenda.actions';
import { bem } from '../../../core/design/bem';
import { useAutoRefresh } from '../../../hooks/useAutoRefresh';
import store from '../../../shared/Store';
import { fetchAppointments } from '../../../store/actions';
import Images from '../../../utils/Images';
import { localeFormat } from '../../../utils/date';
import BlockContainer from '../../BlockContainer';
import CdnImage from '../../CdnImage';
import ImageIcon from '../../ImageIcon';
import WorkshopSessionsCard from '../../workshops/WorkshopSessionsCard';
import { ContainerProps } from '../types';
import './ProgramBlock.scss';
import { useProgramAgenda } from './hooks/useProgramAgenda';
import { Day, DayProgramProps, ProgramConfig } from './types/program.types';

const css = bem('ProgramBlock');
const translationPrefix = 'blocks.program';

const DayProgram = (props: DayProgramProps): JSX.Element => {
  const { program, config = {} } = props;
  const { imageProps = {} } = config;
  const { _id, date, items = [], image: programImage, sideTitle = '' } = program;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dateLabel = t(`${translationPrefix}.date`, { date });
  const hasContent = items.length > 0 || !!programImage?.uri;
  return (
    <Collapsible
      open={isOpen}
      trigger={
        <div className={css('day', { _id })}>
          {!!sideTitle && (
            <Header as="h4" className="side">
              {sideTitle}
            </Header>
          )}
          <Header as="h3" className="date">
            {dateLabel}
          </Header>
          {hasContent && (
            <Icon className="chevron" name={!isOpen ? 'chevron down' : 'chevron up'} />
          )}
        </div>
      }
      transitionTime={200}
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
    >
      {hasContent && (
        <div className={css('details')}>
          <div className="items">
            {items.map(
              (
                { _id: itemId, title, icon, subtitle, location, description, time, image },
                index,
              ) => (
                <div className={cx('item', itemId)} key={itemId || index}>
                  {icon && <ImageIcon icon={icon} className="icon" maxHeight={30} />}
                  <div className="content">
                    {!!time && <div className="time">{time}</div>}
                    <Header as="h4" className="title">
                      {title}
                    </Header>
                    {!!subtitle && <div className="subtitle">{subtitle} </div>}
                    {!!location && (
                      <div className="location">
                        <Icon name="map marker alternate" /> {location}{' '}
                      </div>
                    )}
                    {config.item?.showDescription && !!description && (
                      <div
                        className="description"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}
                  </div>
                  {config.item?.showImage && Images.exists(image) && (
                    <div className="content content--image">
                      <CdnImage
                        className="image"
                        src={image}
                        maxWidth={600}
                        {...config.item?.imageProps}
                      />
                    </div>
                  )}
                </div>
              ),
            )}
          </div>
          {programImage?.uri && (
            <div className="image">
              <CdnImage src={programImage} maxWidth={500} {...imageProps} />
            </div>
          )}
        </div>
      )}
    </Collapsible>
  );
};

DayProgram.defaultProps = {
  config: {},
};

type ProgramBlockProps = {
  container?: ContainerProps;
  days: Day[];
  mode?: 'agenda' | 'custom';
  config?: ProgramConfig;
};

const ProgramBlock = (props: ProgramBlockProps): JSX.Element => {
  const { container, config, mode, days: defaultDays } = props;
  const days = useProgramAgenda(mode || 'custom', defaultDays);

  const { dateFormat = 'PPPP', item: itemConfig, template } = config || {};
  const { variant = 'classic' } = template || {};
  const { blocks, showEndTime = false } = itemConfig || {};

  useMount(() => {
    store.reduxStore.dispatch(fetchAppointments());
    store.reduxStore.dispatch(fetchAgenda());
  });

  return (
    <BlockContainer className={css()} {...container}>
      {days.map((program) => {
        if (variant === 'classic') {
          return <DayProgram key={program._id || program.date} program={program} config={config} />;
        }

        return (
          <div className={css(variant).toString()}>
            <Header as="h3" className="date">
              {localeFormat(parseISO(program.date), dateFormat)}
            </Header>
            <Card.Group className={css('card').toString()} itemsPerRow={3} centered>
              {program?.items.map((item: any) => (
                <WorkshopSessionsCard blocks={blocks} workshop={item} showEndTime={showEndTime} />
              ))}
            </Card.Group>
          </div>
        );
      })}
    </BlockContainer>
  );
};

ProgramBlock.defaultProps = {
  container: {},
  config: {},
  mode: 'custom',
};

export default ProgramBlock;
