import cx from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { useDesignConfig } from '../config/design.context';
import { useIsDesktop } from '../hooks/useMediaQuery';
import { handleChangeLanguage } from '../utils/urlUtils';
import CdnImage from './CdnImage';
import ExpiringNavLink from './ExpiringNavLink';
import EntitySearchBar from './SearchBar/EntitySearchBar';
import { SecondaryMenuEntry } from './menu/AppSecondaryMenu';

const AppHeader = ({ language, languageOptions, secondary, onChange }) => {
  const { logo, logoProps = {}, logoSecondary, logoSecondaryHeight = 100 } = useDesignConfig();
  const { logoPath = '/', altText = '' } = logoProps;
  const desktop = useIsDesktop();

  if (!logo) return null;

  const hasMultipleLanguages = languageOptions && languageOptions.length > 1;
  return (
    <div className="menu--header">
      <ExpiringNavLink to={logoPath}>
        <CdnImage
          as="img"
          className="logo--main"
          src={logo}
          maxHeight={100}
          alt={altText}
          {...logoProps}
        />
      </ExpiringNavLink>
      {logoSecondary && (
        <CdnImage
          as="img"
          src={logoSecondary}
          className="logo--secondary"
          maxHeight={logoSecondaryHeight}
          alt=""
          {...logoProps}
        />
      )}
      {desktop && (
        <EntitySearchBar
          className={cx('menu--header__search--bar', {
            'menu--header__search--bar-shrink': hasMultipleLanguages,
          })}
        />
      )}
      {hasMultipleLanguages && (
        <Dropdown
          className="dropdown--languages"
          placeholder="Language"
          compact
          selection
          options={languageOptions}
          value={language}
          onChange={handleChangeLanguage}
        />
      )}
      {secondary.length > 0 && (
        <div className="secondary">
          {secondary.map((entry) => (
            <SecondaryMenuEntry key={entry.name} entry={entry} onChange={onChange} />
          ))}
        </div>
      )}
    </div>
  );
};

AppHeader.defaultProps = {
  language: 'fr',
  languageOptions: undefined,
  onChange: undefined,
  secondary: [],
};

AppHeader.propTypes = {
  language: PropTypes.string,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  secondary: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

export default AppHeader;
