/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { AddAppointmentToCalendar } from '../../../components/AppointmentAddToCalendarBlock/AppointmentAddToCalendarBlock';
import EntityLink from '../../../components/EntityLink';
import { EntityTags } from '../../../components/EntityTags';
import { bem } from '../../../core/design/bem';
import { UserType } from '../../../core/users/users.types';
import { groupEventsByDays } from '../../../utils/agendaUtils';
import { ConditionContext, validatesCondition } from '../../../utils/conditionUtils';
import { entityForEvent } from '../../../utils/hooks';
import { AddWorkshopToCalendar } from '../../../workshops/blocks/WorkshopAddToCalendarBlock';
import { DeleteEventButton, EventLocation } from '../../components/Schedule';
import { Title } from '../../components/schedule-event/Title';
import './AgendaTab.scss';

const css = bem('AgendaTab');

type AgendaTabProps = {
  user: UserType;
  condition?: ConditionContext;
  config: Record<string, any>;
  tagFields?: string[];
};

type Event = {
  _id: string;
  startDate: string;
  endDate?: string;
  type: string;
} & Record<string, any>;

function getDefaultDay(events: Event[]) {
  if (!events?.length) return null;
  const today = events.find((e) => moment(e.startDate).isSame(new Date(), 'day'));
  if (today) return moment().format('YYYY-MM-DD');
  return moment(events[0].startDate).format('YYYY-MM-DD');
}

const AgendaEvent = ({ event, tagFields }: { event: Event; tagFields?: string[] }) => {
  const { t } = useTranslation();
  const { appointment, endDate, mandatory, startDate, group, _type, location } = event;

  return (
    <div className={css('event')}>
      <div className="time">
        {t(`profile.event-table.item.${!endDate ? 'startDate' : 'range'}`, {
          startDate,
          endDate,
        })}
      </div>
      <div className="title">
        {/* @ts-ignore */}
        <EntityLink entity={entityForEvent(event)}>
          <Title event={event} />
        </EntityLink>
      </div>
      {tagFields && <EntityTags tagFields={tagFields} entity={event} type={_type} />}
      <EventLocation location={location} />
      <div className="actions">
        {(_type === 'workshop' || _type === 'session') && (
          <AddWorkshopToCalendar workshop={event} primary />
        )}
        {_type === 'appointment' && (
          <AddAppointmentToCalendar appointment={appointment} sponsor={group} primary />
        )}
        {!mandatory && <DeleteEventButton event={event} icon="trash outline alternate" />}
      </div>
    </div>
  );
};

export const AgendaTab: FC<AgendaTabProps> = ({ condition, tagFields }) => {
  const { t } = useTranslation();
  const events = useSelector(userAgenda);
  const filteredEvents = condition
    ? events.filter((e) => validatesCondition(condition, e))
    : events;
  const { days, eventsByDay } = groupEventsByDays(filteredEvents);
  const [activeDay, setActiveDay] = useState<string | null>('');
  useEffect(() => setActiveDay(getDefaultDay(events)), [events]);

  if (!filteredEvents.length) {
    return <div className={css()}> {t('profile.schedule.empty-agenda')}</div>;
  }

  return (
    <div className={css()}>
      <div className={css('days')}>
        {days.map((day) => {
          const date = moment(day).format('DD');
          const month = moment(day).format('MMM');
          return (
            <div
              className={css('day').is({ active: day === activeDay })}
              onClick={() => setActiveDay(day)}
            >
              <div className="date">{date}</div>
              <div className="month">{month}</div>
            </div>
          );
        })}
      </div>
      {activeDay && (
        <div className={css('events')}>
          {eventsByDay[activeDay].map((event) => (
            <AgendaEvent event={event} tagFields={tagFields} />
          ))}
        </div>
      )}
    </div>
  );
};
