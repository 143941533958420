import { parseISO } from 'date-fns';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';

import { localeFormat, momentTz } from './date';
import { getWorkshopStatus, isNow } from './dateUtils';

function toDate(value, withTime = false) {
  if (!value) return value;
  const defaultFormat = 'YYYY-MM-DD';
  const dateFormat = withTime ? `${defaultFormat} HH:mm` : defaultFormat;
  return momentTz(parseISO(value)).format(dateFormat);
}

export function generateWorkshopStatus(workshops, options) {
  return (workshops || []).map((workshop) => ({
    ...workshop,
    workshopStatus: getWorkshopStatus(workshop, options),
    isLive: isNow(workshop, options),
  }));
}

function groupByWithArrays(collection, iteratee) {
  function add(result, key, value) {
    if (hasOwnProperty.call(result, key)) {
      // eslint-disable-next-line no-param-reassign
      result[key].push(value);
    } else {
      // eslint-disable-next-line no-param-reassign
      result[key] = [value];
    }
  }
  let mapper = iteratee;
  if (typeof iteratee !== 'function') {
    mapper = (value) => get(value, iteratee);
  }
  return reduce(
    collection,
    (result, value) => {
      const key = mapper(value);
      if (Array.isArray(key)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const k of key) {
          add(result, k, value);
        }
      } else {
        add(result, key, value);
      }
      return result;
    },
    {},
  );
}

export function generateGroups(data, groupByConfig) {
  const { field, type, dateFormat = 'PPPP', withTime, options } = groupByConfig;
  if (type === 'date' || field === 'startDate') {
    const groups = groupBy(data, (item) => {
      const value = item[field] || item?.sessions[0]?.[field];
      if (!value) return '';
      return toDate(value, withTime);
    });

    return Object.keys(groups)
      .sort()
      .map((key) => ({
        id: key,
        label: key ? localeFormat(parseISO(key), dateFormat) : '',
        items: groups[key],
      }));
  }

  // TODO: order keys ?
  const groups = groupByWithArrays(data, field);

  if (options && options.length > 0) {
    // Manual order/label
    return options
      .map(({ value, label, ...rest }) => ({
        id: value,
        label,
        ...rest,
        items: groups[value] || [],
      }))
      .filter((opt) => opt.items.length > 0);
  }

  return Object.keys(groups).map((key) => ({
    id: key,
    label: key,
    items: groups[key],
  }));
}
