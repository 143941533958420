import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Card, Label } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { useComponentOptions } from '../../config/design.context';
import { eventTags } from '../../core/trackers/events';
import i18n from '../../translations/i18n';
import { getEntityString } from '../../utils';
import { ensureArray } from '../../utils/arrayUtils';
import { useItemValueColor } from '../../utils/designUtils';
import { replaceValues } from '../../utils/stringUtils';
import CdnImage from '../CdnImage';
import EntityLink from '../EntityLink';
import { ExhibitorFavorite } from './ExhibitorFavorite';
import './SponsorCard.scss';

export function getSponsorString(sponsor, key) {
  return getEntityString(sponsor, sponsor.collection || 'sponsor', key);
}

const defaultTags = [
  {
    field: 'category',
    icon: 'trophy',
  },
];

const SponsorTag = ({ tag = {}, item }) => {
  const { icon = 'trophy' } = tag;
  const values = ensureArray(get(item, tag.field));
  const { style } = useItemValueColor('users', item, tag.field);

  if (!values.length) return null;

  return (
    <>
      {values.map((value) => (
        <Label
          key={value}
          icon={icon}
          className={`${icon}--${value}`}
          style={style}
          content={i18n.t(`${item.collection || 'sponsor'}.${tag.field}.${value}`, {
            defaultValue: value,
          })}
        />
      ))}
    </>
  );
};

const SponsorCard = ({ item, tagFields, headerTemplate, allowFavorites }) => {
  const { trackEvent } = useTracking();
  const { _id, collection, shortDescription = '', logo, name } = item;
  const tags = tagFields?.length ? tagFields : defaultTags;
  const imageProps = useComponentOptions('company-logos');
  const categoryColor = useItemValueColor('users', item, 'category');
  const computedName = headerTemplate ? replaceValues(headerTemplate, item) : name;
  return (
    <Card
      className={`card--sponsor ${collection}--${_id}`}
      as={EntityLink}
      entity={item}
      style={{ '--ac-color-category': categoryColor }}
      onClick={() => trackEvent(eventTags.SPONSOR_GO_TO_PAGE, { item })}
    >
      <ExhibitorFavorite exhibitor={item} allowFavorites={allowFavorites} />
      {logo && (
        <Card.Content style={{ flexGrow: 0 }} className="logo">
          <CdnImage
            as="img"
            src={logo}
            alt="logo"
            className="logo"
            maxHeight={90}
            {...imageProps}
          />
        </Card.Content>
      )}
      <Card.Content className="information">
        <Card.Header> {computedName}</Card.Header>
        <Card.Description>
          {tags.map((tag) => (
            <SponsorTag key={tag.field} item={item} tag={tag} />
          ))}
        </Card.Description>
        <Card.Description>{shortDescription}</Card.Description>
      </Card.Content>
    </Card>
  );
};

SponsorCard.defaultProps = {
  allowFavorites: false,
};

SponsorCard.propTypes = {
  allowFavorites: PropTypes.bool,
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    category: PropTypes.string,
    collection: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
  }).isRequired,
};

export default SponsorCard;
